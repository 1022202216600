<template>
  <v-row :justify="getConfigView.justify" align="center" class="ma-0 pa-0">
    <v-col cols="3" class="pa-0" v-if="getConfigView.col">
      <icon-tag
        v-if="itsImmediate"
        :icon="iconData.icon"
        :span="iconData.span"
        :background="iconData.background"
        :iconColor="iconData.iconColor"
      />
    </v-col>
    <icon-tag
      v-else-if="itsImmediate"
      icon="mdi-lightning-bolt"
      span="Este Lead fue atendido en menos de 5 minutos desde que ingresó al
          sistema"
      background="amber lighten-3"
      iconColor="amber darken-2"
    />
    <v-col cols="3" class="pa-0" v-if="getConfigView.col">
      <icon-multioportunity :lead="lead" />
    </v-col>
    <icon-multioportunity v-else :lead="lead" />

    <v-col cols="3" v-if="getConfigView.col" class="mt-2 pa-0">
      <icon-brex v-if="brex" />
    </v-col>
    <icon-brex v-else-if="brex" />
    <div class="caption grey--text" v-if="getCreatedAtDate != ''">
      {{ getCreatedAtDate }}
    </div>
  </v-row>
</template>

<script>
export default {
  components: {
    IconTag: () =>
      import("@/components/lead/components/tagsComponents/IconTag.vue"),
    IconBrex: () =>
      import("@/components/lead/components/tagsComponents/IconBrex.vue"),
    IconMultioportunity: () =>
      import(
        "@/components/lead/components/tagsComponents/IconMultiOportunity.vue"
      )
  },
  props: {
    itsImmediate: {
      type: Boolean,
      default: false
    },
    itsMultiOportunity: {
      type: Boolean,
      default: false
    },
    brex: { type: Boolean, default: false },
    getCreatedAtDate: {
      type: String,
      default: ""
    },
    lead: {
      type: Object,
      default: function() {
        return {};
      }
    },
    view: {
      type: String,
      default: "table"
    }
  },
  data() {
    return {
      userRole: localStorage.getItem("user_role") || "",
      viewConfig: {
        table: { justify: "center", col: true },
        kanban: { justify: "space-between", col: false }
      },
      iconData: {
        icon: "mdi-lightning-bolt",
        span:
          "Este Lead fue atendido en menos de 5 minutos desde que ingresó al sistema",
        background: "amber lighten-3",
        iconColor: "amber darken-2"
      }
    };
  },
  methods: {},
  computed: {
    getConfigView() {
      return this.viewConfig[this.view];
    }
  }
};
</script>
